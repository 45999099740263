<template>
    <v-data-table
        :headers="headers"
        :items="entities"
        class="elevation-1"
        :footer-props="{ 'itemsPerPageOptions': [50, 100, 200, -1] }"
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\reviews\\list.5809') }}</v-toolbar-title>
                <v-spacer />
                <v-btn v-if="user.adminAccess && user.adminAccess.reviews >= ACCESS.WRITE" color="primary" @click="create">
                    <v-icon>mdi-plus</v-icon>{{ $t('src\\views\\reviews\\list.3240') }}</v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.date`]="{ item }">
            {{ item.date | moment('DD.MM.YYYY') }}
        </template>
        <template v-slot:[`item.trip`]="{ item }">
            {{ item.trip.name }}
        </template>
        <template v-slot:[`item.organizer`]="{ item }">
            {{ item.trip.user.firstname }} {{ item.trip.user.lastname }}
        </template>
        <template v-slot:[`item.user`]="{ item }">
            <template v-if="item.user">
                <span style="color:green">{{ item.user.firstname }} {{ item.user.lastname }}</span>
            </template>
            <template v-else-if="item.externalUser">
                <span style="color:red">{{ item.externalUser.firstname }} {{ item.externalUser.lastname }}</span>
            </template>
        </template>
        <template v-slot:[`item.rating`]="{ item }">
            {{ item.rating.overall }}
        </template>
        <template v-slot:[`item.proof`]="{ item }">
            {{ item.proofLink || item.proofPicture ? 'Да' : 'Нет' }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
            {{ $t(REVIEW_STATUS_TITLE[item.status]) }}
            <v-menu v-if="user.adminAccess && user.adminAccess.reviews >= ACCESS.WRITE">
                <template v-slot:activator="{ attrs, on }">
                    <v-icon
                        color="primary"
                        small
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                    >mdi-pencil</v-icon>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(status, index) in REVIEW_STATUS_TITLE"
                        :key="index"
                        @click="setStatus(item._id, index)"
                    >
                        <v-list-item-title>{{ $t(status) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
            <v-icon color="primary" @click="edit(item._id)">mdi-pencil</v-icon>
        </template>
    </v-data-table>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { REVIEW_STATUS_TITLE } from '@/vars';
    import { ACCESS } from '@/vars';

    export default {
        name: 'ReviewsList',
        components: {
        },
        data: () => ({
            ACCESS,
            REVIEW_STATUS_TITLE,
            headers: [],
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('reviews', ['entities']),
        },
        async mounted() {
            await store.dispatch('auth/fetch');
            await store.dispatch('reviews/fetch', {});
            this.headers = [
                { text: this.$t('src\\views\\reviews\\list.createdAt'), value: 'createdAt' },
                { text: this.$t('src\\views\\reviews\\list.date'), value: 'date' },
                { text: this.$t('src\\views\\reviews\\list.trip'), value: 'trip' },
                { text: this.$t('src\\views\\reviews\\list.user'), value: 'user' },
                { text: this.$t('src\\views\\reviews\\list.status'), value: 'status' },
                { text: this.$t('src\\views\\reviews\\list.rating'), value: 'rating' },
                { text: this.$t('src\\views\\reviews\\list.proof'), value: 'proof' },
            ];
            if(this.user && this.user.adminAccess && this.user.adminAccess.reviews >= ACCESS.WRITE) {
                this.headers.push({ text: '', align: 'right', value: 'edit' })
            }
        },
        methods: {
            async create() {
                await this.$router.push({ name: 'reviews-create' });
            },
            async edit(id) {
                await this.$router.push({ name: 'reviews-edit', params: { id } });
            },
            async remove(id) {
                await store.dispatch('reviews/delete', { id });
                await store.dispatch('reviews/fetch', {});
            },
            async setStatus(id, status) {
                await store.dispatch('reviews/setStatus', { id, status });
                await store.dispatch('reviews/fetch', {});
            }
        }
    }
</script>
